import request from '@/utils/request'

// 获取在线用户
export function getMonitorUser() {
    return request({
        url: '/socket/socket/getLoginManager',
        method: 'GET'
    })
}

// 强退
export function forceOut(val) {
    return request({
        url: '/socket/socket/force/' + val ,
        method: 'DELETE'
    })
}
