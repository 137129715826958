<template>
    <div class="app-container">
        <el-form :inline="true" :model="queryParams" :size="$store.getters.size" ref="queryParams">
            <el-form-item label="账户名">
                <el-input type="text" v-model="queryParams.roleName" placeholder="账户名" clearable></el-input>
            </el-form-item>
            <!--<el-form-item>
              <el-button type="primary" @click="handleQuery">查询</el-button>
            </el-form-item>-->
        </el-form>

        <el-table v-loading="loading" @selection-change="handleSelectionChange"
                  :size="$store.getters.size" row-key="token" :data="tableData" >
            <el-table-column type="selection" width="55" align="center"></el-table-column>
            <el-table-column label="会话编号" prop="token" show-overflow-tooltip align="center"></el-table-column>
            <el-table-column label="登录名" prop="userName" align="center"></el-table-column>
            <el-table-column label="昵称" prop="nickName" align="center"></el-table-column>
            <el-table-column label="主机" prop="ip" width="240" align="center"></el-table-column>
            <el-table-column label="登录地址" prop="city" width="240" align="center"></el-table-column>
            <el-table-column label="登录时间" prop="loginTime" width="230" align="center"></el-table-column>
            <el-table-column label="操作" width="300" align="center">
                <template slot-scope="scope">
                    <el-button type="text" icon="el-icon-delete" @click="force(scope.row)"
                               v-if="$app.haveRole('system:monitor:force')">强退</el-button>
                </template>
            </el-table-column>
        </el-table>
    </div>
</template>

<script>
    import {getMonitorUser, forceOut} from '@/api/system/socket'

    export default {
        name: "Monitor",
        data(){
            return {
                queryParams: {
                    roleName: undefined
                },
                loading: false,
                tableData: [],
                total: 0,
                multipleSelection: []
            }
        },
        mounted() {
            this.getTableData();
        },
        methods: {
            getTableData(){
                getMonitorUser().then(({code, data}) => {
                    if(code === 0){
                        this.tableData = data;
                    }
                })
            },
            /** 强退 */
            force(row){
                this.$confirm(`是否确认强退名称为[ ${row.userName} ]的数据项?`,'提示', {
                    type: "warning"
                }).then(() => {
                    forceOut(row.token).then(({code}) => {
                        if(code === 0){
                            this.$message.success("强退成功")
                            this.getTableData();
                        }
                    })
                }).catch(()=>{})
            },
            handleSelectionChange(val){
                this.multipleSelection = val;
            }
        }
    }
</script>

<style scoped>

</style>
